import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import momentLocalizer from 'react-widgets-moment';
import store from './store';
import AppContainer from './containers/App';
import * as serviceWorker from './serviceWorker';
import './i18n';
momentLocalizer();

const container = document.getElementById('root'); // Changed from 'app' to 'root'
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Router>
      <AppContainer />
    </Router>
  </Provider>
);
